.EInvoice-basic-features-bottom {
  display: flex;
  align-items: flex-start;
  gap: var(--xl, 24px);
  flex-wrap: wrap;
}

.basic-feature-container {
  display: flex;

  gap: var(--md, 8px);
  width: 30.33%;

  margin-bottom: 20px;
}

.basic-feature-text {
  color: var(--333333, rgba(0, 0, 0, 0.72));

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 768px) {
  .EInvoice-basic-features-bottom {
    display: flex;
    align-items: center;
    gap: var(--md, 8px);
  }

  .basic-feature-container {
    display: flex;
    align-items: flex-start;
    gap: var(--md, 8px);
    width: 48%;
  }

  .basic-feature-container img {
    width: var(--xl, 24px);
    height: var(--xl, 24px);
  }

  .basic-feature-text {
    color: var(--333333, rgba(0, 0, 0, 0.72));

    /* text-14meduim */
    font-family: "IBM Plex Sans Arabic";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
