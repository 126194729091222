.EReceipt-software2-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);

  align-self: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.EReceipt-software {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--lg, 16px);
  align-self: stretch;
  width: 30.33%;
}

.EReceipt-software-img-container {
  display: flex;
  width: 140px;
  height: 140px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 124px;
  border: 2px solid var(--Foundation-Blue-Normal, #1ec2f3);
  position: relative;
  transition: all 0.2s;
}

.EReceipt-software-bottom-img {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.EReceipt-software-span {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: -5px;

  top: 3px;
  border-radius: var(--Spacing-space-section, 32px);
  background: var(--Foundation-Blue-Normal, #1ec2f3);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.32);
  color: var(--white, #fff);
  text-align: center;

  /* text-20semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.2s;
}

.EReceipt-software-bottom-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--lg, 16px);
  align-self: stretch;
}

.EReceipt-software-bottom-head {
  color: var(--Dark, #333);
  text-align: center;

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.EReceipt-software-bottom-text {
  color: var(--333333, rgba(0, 0, 0, 0.72));
  text-align: center;

  /* TEXT-18regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.EReceipt-software:hover {
  .EReceipt-software-img-container {
    background: var(--foundation-blue-light-active, #b9ecfb);
  }
  .EReceipt-software-span {
    background: var(--white, #fff);
    color: var(--Foundation-Blue-Normal, #1ec2f3);
  }
}

@media (max-width: 768px) {
  .EReceipt-software2-bottom {
    display: flex;
    align-items: flex-start;
    gap: var(--lg, 16px);
    align-self: stretch;
  }

  .EReceipt-software {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--lg, 16px);
    align-self: stretch;
    width: 47%;
  }
  .EReceipt-software-img-container {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .EReceipt-software-bottom-img {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .EReceipt-software-span {
    display: flex;
    width: var(--Spacing-space-section, 32px);
    height: var(--Spacing-space-section, 32px);
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: -20.333px;
    top: 7px;
    text-align: center;

    /* text-16semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .EReceipt-software-bottom-head {
    color: var(--Dark, #333);
    text-align: center;

    /* text-16semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .EReceipt-software-bottom-text {
    color: var(--333333, rgba(0, 0, 0, 0.72));
    text-align: center;

    /* text-12regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
