.odoo-productivity-bottom {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-self: stretch;
  flex-wrap: wrap;
}

.odoo-productivity-bottomLeft {
  width: 65%;
  height: 320px;
  margin-left: -10rem;
  background: url(../../assets//odooProductivityBackGround1.png);
  background-size: 100% 50%;
  background-repeat: no-repeat;
  padding: 2rem;
}

.odoo-productivities {
  margin-left: 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sm-secion, 40px);
}

.odoo-productivity {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.productivity {
  display: flex;
  align-items: center;
  gap: var(--md, 8px);
}
.produtivity-img{
    width: 30px;
    height: 30px;
}
.productivity-text {
  color: var(--Dark, #333);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.05rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


@media (max-width: 480px) {
  .odoo-productivities{
    flex-direction: row;
  }
  .odoo-productivity{
    flex-direction: column;
  }
  .odoo-productivity-bottom{
    flex-direction: column-reverse;
  }
  .odoo-productivity-bottomLeft{
    width: 91%;
    height: 450px;
  }

  .productivity{
    width: 100%;
  }

  .odoo-productivity-img{
    width: 100%;
  }
}