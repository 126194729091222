.nav-bar {
  width: 100%;
  height: 100%;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.nav-bar_logo {
  width: 124px;
  height: 62px;
}

.nav-bar_content {
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: flex;
}

.nav-bar_content__text {
  text-align: center;
  color: rgba(0, 0, 0, 0.72);
  font-size: 1.2rem;
  font-family: IBM Plex Sans Arabic;
  font-weight: 600;
  word-wrap: break-word;
  cursor: pointer;
}

.nav-bar_content__compose {
  display: flex;
  align-items: center;
  gap: var(--Spacing-xs, 4px);
  align-self: stretch;
}

.nav-bar_content__composes {
  display: inline-flex; /* Keep it inline with other navbar items */
  flex-direction: column; /* Align the "Services" text and dropdown arrow in a column */
  gap: 8px;
  position: relative; /* Required for dropdown positioning */
}

.nav-bar_content__services {
  display: none; /* Initially hide the dropdown */
  position: absolute; /* Position it relative to the "Services" link */
  top: 100%; /* Align it right below the "Services" link */
  left: 0; /* Align it with the left side of the "Services" link */
  background: #e9f9fe;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  width: 200px;
  z-index: 10000;
}

.nav-bar_content__composes:hover {
  .nav-bar_content__services {
    display: flex; /* Show the dropdown on hover */
  }
}

.service {
  color: var(--Dark, #333);

  /* text-16regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s;
  padding: var(--lg, 16px) var(--md, 8px);
}

.nav-bar_content__text:hover {
  color: #2b5996ed;
}

.service:hover {
  background: var(--Foundation-Blue-Normal, #2b5996);
  border-radius: var(--md, 8px);
  color: var(--white, #fff);
}

.active {
  color: #2b5996ed;
}

.arrow-active {
  border: solid #2b5996ed;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-active2 {
  border: solid #2b5996;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow {
  border: solid rgba(0, 0, 0, 0.72);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.nav-link {
  color: rgba(0, 0, 0, 0.72);
  text-decoration: none;
}

.nav-link:hover {
  color: #2b5996ed;
}

.nav-bar-mobile {
  display: none;
  justify-content: space-between;
  align-items: center;
}

.nav-bar-mobile-logo {
  width: 88px;
  height: 44px;
  flex-shrink: 0;
}

.nav-bar-mobile-hm-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--md, 8px);
  background: var(--light-32, rgba(234, 238, 245, 0.32));
  cursor: pointer;
}

.drawer {
  display: flex;
  padding: var(--Spacing-xl, 24px) var(--Spacing-lg, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.drawer-content-link {
  display: flex;
  padding-bottom: var(--Spacing-lg, 16px);

  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: var(--Color-80, rgba(0, 0, 0, 0.8));

  /* text-16regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-decoration: none;
}

.drawer-content-compose {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--md, 8px);
  align-self: stretch;
}

.drawer-content-compose1 {
  display: flex;
  padding-bottom: var(--Spacing-lg, 16px);
  align-items: center;
  gap: var(--Spacing-xs, 4px);
  align-self: stretch;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.08);
}

.drawer-content-compose1-text {
  color: var(--Color-80, rgba(0, 0, 0, 0.8));

  /* text-16regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drawer-content-compose2 {
  display: flex;
  padding: var(--lg, 16px) var(--sm, 4px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lg, 16px);
  align-self: stretch;
  border-radius: var(--md, 8px);
  background: var(--Foundation-Blue-Light, #e9f9fe);
}

.drawer-content-compose2-text {
  display: flex;
  padding: var(--md, 8px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Dark, #333);
  /* text-16regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 768px) {
  .nav-bar-mobile {
    display: flex;
  }
  .nav-bar {
    display: none;
  }

  .arrow-active2 {
    border-width: 0 1px 1px 0;
  }
}
