.section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.section-topContent {
  display: flex;
  align-items: center;
  gap: var(--lg, 16px);
  align-self: stretch;
}

.section-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children to the start of the container */
  margin: 0;
  padding: 0;
}

.section-text1 {
  color: var(--Foundation-Blue-Light, #eaeef5);
  font-family: "IBM Plex Sans Arabic";
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1; /* Adjust the line-height to reduce spacing */
}

.section-text2 {
  color: var(--Foundation-Blue-Normal, #2b5996);
  font-family: "IBM Plex Sans Arabic";
  font-size: 2rem;
  font-weight: 600;
  margin-top: -0.6rem; /* Ensure no top margin */
  position: relative;
  top: -1rem; /* Adjust the vertical position if necessary */
}

.section-bottomContent {
  display: flex;
  align-items: flex-start;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.section-bottomContentLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sm-secion, 20px);
  align-self: stretch;
}

.section-bottomContentLeft_text1 {
  color: var(--Foundation-Blue-Normal, #1ec2f3);

  /* text-48semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section-bottomContentLeft_text2 {
  color: var(--Color-80, rgba(0, 0, 0, 0.8));

  /* H4 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 166.667% */
}

.section-bottomContentLeft_composes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.section-bottomContentLeft_compose {
  display: flex;
  align-items: center;
  gap: var(--md, 8px);
  align-self: stretch;
}

.section-bottomContentLeft_composeIcon {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: var(--md, 8px);
  background-color: var(--Foundation-Blue-Normal, #1ec2f3);
  background-size: cover;
  position: relative;
}

.section-bottomContentLeft_composeImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-bottomContentLeft_composeText {
  color: var(--Dark, #333);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 768px) {
    .section-bottomContent {
      flex-direction: column-reverse; /* Reverse the layout on smaller screens */
      gap: 20px;
    }

    
    .section-bottomContentRight{
      width: 100%;
    }

    .section-bottomContentRight-img{
      width: 100%;
    }
    .section-text {
      flex-direction: column; /* Stack text vertically on smaller screens */
      align-items: flex-start;
    }
  
    .section-text2 {
      font-size: 1.5rem;
      margin-left: 0; /* Remove left margin on smaller screens */
    }
  
    .section-bottomContentLeft_text1 {
      font-size: 1.5rem;
    }
  
    .section-bottomContentLeft_text2 {
      font-size: 0.875rem;
    }
  
    .section-bottomContentLeft_compose {
      flex-direction: row;
      gap: 10px;
    }
  
    .section-bottomContentLeft_composeIcon {
      width: 40px;
      height: 40px;
    }
  
    .section-bottomContentLeft_composeText {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .section-text1 {
      font-size: 2rem;
    }
  
    .section-text2 {
      font-size: 1.25rem;
      margin-top: 0rem;
    }
  
    .section-bottomContentLeft_text1 {
      font-size: 1.25rem;
    }
  
    .section-bottomContentLeft_text2 {
      font-size: 0.75rem;
    }
  
    .section-bottomContentLeft_composeIcon {
      width: 30px;
      height: 30px;
    }
  
    .section-bottomContentLeft_composeText {
      font-size: 0.75rem;
    }
  }
