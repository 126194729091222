.challenge-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
}

.company-challenga-mobileView {
  display: none;
}

@media (max-width: 768px) {
  .company-challenga-mobileView {
    display: block;
  }

  .company-challenga-regularView {
    display: none;
  }
}
