.counter-container {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    margin-top: 56px;
  }
  
  .counter-content {
    background-color: #1a355a;
    padding: 15px 5%;
    display: flex;
    flex-wrap: wrap; /* Allows the items to wrap on smaller screens */
    border-radius: 10px;
    justify-content: space-between;
    clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0% 100%);
    position: relative;
    overflow: hidden;
  }
  
  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 24px 16px;
    flex: 1 1 20%; /* Flex-basis for responsiveness */
    max-width: 200px; /* Set max width to prevent overflow on large screens */
  }
  
  .counter-icon {
    width: 60px; /* Adjust size for responsiveness */
    height: 60px;
  }
  
  .counter-number {
    font-family: "IBM Plex Sans Arabic";
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--white, #fff);
    text-align: center;
  }
  
  .counter-span {
    color: var(--Foundation-Blue-Normal, #1ec2f3);
  }
  
  .counter-text {
    font-family: "IBM Plex Sans Arabic";
    font-size: 1rem;
    font-weight: 500;
    color: var(--white, #fff);
    text-align: center;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    .counter {
      flex: 1 1 25%; /* Adjust to 4 columns */
    }
  }
  
  @media (max-width: 992px) {
    .counter-content {
      padding: 15px 3%; /* Reduce padding for medium screens */
    }
    .counter {
      flex: 1 1 33%; /* Adjust to 3 columns */
    }
  }
  
  @media (max-width: 768px) {
    .counter {
      flex: 1 1 50%; /* Adjust to 2 columns */
    }
  }
  
  @media (max-width: 576px) {
    .counter {
      flex: 1 1 50%; /* Adjust to 1 column on small screens */
    }
    .counter-icon {
      width: 50px;
      height: 50px; /* Smaller icons on small screens */
    }
  }
  