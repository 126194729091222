.EReceipt-banner {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align the content higher */
  width: 100%;
  height: 100vh; /* Full-screen height */
  background: url("../../assets/EReceiptBanner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 7rem 2rem; /* Increase padding at the top */
  position: relative;
}

.EReceipt-banner-content {
  z-index: 2; /* Make sure the content is above the overlay */
  color: #fff;
  max-width: 1200px;
  padding: 2rem;
  text-align: center;
  margin-top: 4rem; /* Move content further from the top */
}

.EReceipt-text {
  color: var(--white, #fff);
  text-align: center;

  /* H1 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 128.571% */
}

.EReceipt-text2 {
  color: var(--Foundation-Blue-Normal, #1ec2f3);
  text-align: center;

  /* text-48semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 768px) {
  .EReceipt-text {
    color: var(--white, #fff);
    text-align: center;
    /* text-24semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .EReceipt-text2 {
    color: var(--Foundation-Blue-Normal, #1ec2f3);
    text-align: center;

    /* text-20semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
