.company-challenge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sm-secion, 40px);
  align-self: stretch;
}

.company-challenge-bottom {
  display: flex;
  /* height: 1035px; */
  padding: var(--md-section, 48px) 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lg-section, 56px);
  align-self: stretch;
  background: rgba(233, 249, 254, 0.08);
}

.company-challenge-bottom-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sm-secion, 40px);
  align-self: stretch;
}

.problem-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--md, 8px);
}

.problem-text {
  color: var(--Foundation-Blue-Normal, #1ec2f3);
  text-align: center;

  /* text-40semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.problem-content-container {
  width: 100%;
}

.problem-content-img {
  width: 100%;
}

.copmany-challenge-bottom-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sm-secion, 40px);
  align-self: stretch;
}

.solution-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--md, 8px);
}

.solution-text {
  color: var(--Foundation-Blue-Normal, #2b5996);
  text-align: center;

  /* text-40semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.solution-content-container {
  width: 100%;
}

.solution-content-img {
  width: 100%;
}

@media (max-width: 768px) {
  .problem-container-img {
    width: 64px;
  }
  .solution-container-img{
    width: 64px;
  }
  .problem-text {
    font-size: 20px;
  }
  .solution-text{
    font-size: 20px;
  }

  .company-challenge-img {
    width: 124px;
    height: 69.75px;
  }
}
