.service-card {
  display: flex;
  width: 30.333%;
  padding: var(--xl, 24px) var(--lg, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--lg, 5px);
  border-radius: var(--md, 8px);
  background: #e9f9fe;
  transition: all 0.2s;
  height: 340px;
  cursor: pointer;
}

.service-card-img {
  width: 120px;
  height: 120px;
}

.service-card-head {
  color: var(--Dark, #333);
  text-align: center;
  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.service-card-text {
  color: var(--Dark, #333);
  text-align: center;

  /* text-16regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.service-card:hover {
  border-radius: 16px;
  border: 2px solid var(--white, #fff);
  background: linear-gradient(180deg, #497cbe 0%, #2fb8ea 100%);
  box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, 0.32);
  .service-card-head {
    color: var(--white, #fff);
  }
  .service-card-text {
    color: var(--white, #fff);
  }
}

/* Responsive for tablets */
@media (max-width: 768px) {
  .service-card {
    width: 45%; /* Adjust the card width for tablets */
    padding: var(--lg, 16px) var(--md, 12px);
  }

  .service-card-img {
    width: 100px; /* Smaller image for tablets */
    height: 100px;
  }

  .service-card-head {
    font-size: 1.25rem; /* Smaller font size for tablets */
  }

  .service-card-text {
    font-size: 0.875rem; /* Smaller font size for tablets */
  }
}

/* Responsive for mobile phones */
@media (max-width: 480px) {
  .service-card {
    width: 100%; /* Full width for mobile */
    padding: var(--md, 12px) var(--sm, 8px);
    height: auto; /* Let the height be automatic */
  }

  .service-card-img {
    width: 80px; /* Smaller image for mobile */
    height: 80px;
  }

  .service-card-head {
    font-size: 1.125rem; /* Smaller font size for mobile */
  }

  .service-card-text {
    font-size: 0.75rem; /* Smaller font size for mobile */
  }
}

.service-card:hover {
  border-radius: 16px;
  border: 2px solid var(--white, #fff);
  background: linear-gradient(180deg, #497cbe 0%, #2fb8ea 100%);
  box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, 0.32);
}

.service-card:hover .service-card-head {
  color: var(--white, #fff);
}

.service-card:hover .service-card-text {
  color: var(--white, #fff);
}
