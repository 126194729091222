.partner-card {
  display: flex;
  height: 144px;
  padding: 8px 39px;
  justify-content: center;
  align-items: center;
  flex: 1 0 20%; /* Default width for larger screens */
  border-radius: var(--lg, 16px);
  background: linear-gradient(
    104deg,
    rgba(47, 184, 234, 0.26) 6.58%,
    #e9f9fe 71.62%
  );
  box-sizing: border-box;
}

.partner-card-img{
  width: 100%;
  height: 119.147px;
}

@media (max-width: 1200px) {
  .partner-card {
    flex: 1 0 30%; /* Adjust width for medium screens */
  }
}

@media (max-width: 992px) {
  .partner-card {
    flex: 1 0 45%; /* Adjust width for tablet screens */
  }
}

@media (max-width: 768px) {
  .partner-card {
    flex: 1 0 48%; /* Adjust width for smaller tablets */
    padding: 0px 0px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 576px) {
  .partner-card {
    flex: 1 0 48%; /* Two cards per row on mobile screens */
   
  }

  .partner-card-img{
    width: 100%;
    height: 120px;
  }
}