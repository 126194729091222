.svg-mobile-view {
  display: none;
}

@media (max-width: 480px) {
  .svg-mobile-view{
    display: block;
  }

  .regular-oddo-svg{
    display: none;
  }
}
