.odoo-webSite-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
}
.odoo-webSite-text {
  color: var(--Foundation-Blue-Darker, #0f1f35);

  /* text-32meduim */
  font-family: "IBM Plex Sans Arabic";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Responsive styles for tablets and smaller screens */
@media (max-width: 1024px) {
  .odoo-webSite-bottom {
    flex-direction: column;
    gap: 16px;
  }

  .odoo-webSite-text {
    font-size: 28px;
  }
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .odoo-webSite-bottom {
    padding: 16px;
  }

  .odoo-webSite-text {
    font-size: 24px;
    text-align: left;
  }

  .odoo-webSite-bottom img {
    max-width: 100%;
    height: auto;
  }
}

/* Responsive styles for very small screens */
@media (max-width: 480px) {
  .odoo-webSite-text {
    font-size: 20px;
    line-height: 1.4;
  }

  .odoo-webSite-bottom {
    padding: 8px;
    flex-direction: column-reverse;
  }
}
