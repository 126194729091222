.oddoo-banner {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align the content higher */
  width: 100%;
  height: 100vh; /* Full-screen height */
  background: url("../../assets/odooBanner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 7rem 2rem; /* Increase padding at the top */
  position: relative;
}

.oddoo-banner-content {
  z-index: 2; /* Make sure the content is above the overlay */
  color: #fff;
  max-width: 1200px;
  padding: 2rem;
  text-align: center;
  margin-top: 2rem; /* Move content further from the top */
}

.oddoo-banner-text {
  font-size: 3rem;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  color: #fff;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.odoo-banner-subText {
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 480px) {
  .oddoo-banner {
    padding: 0px 0px;
  }

  .oddoo-banner-content {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: var(--lg, 16px);
  }

  .oddoo-banner-text {
    color: var(--white, #fff);
    text-align: center;

    /* text-24semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .odoo-banner-subText {
    color: var(--white, #fff);
    text-align: center;

    /* text-16regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
