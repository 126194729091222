.EReceipt-software-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.EReceipt-software-bottom-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lg-section, 56px);
  flex: 1 0 0;
}

.EReceipt-software-bottom-left-text1 {
  color: var(--Foundation-Blue-Normal, #2b5996);

  /* text-48semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.software-text1-highlight {
  color: var(--Foundation-Blue-Normal, #1ec2f3);
}

.EReceipt-software-bottom-left-text2 {
  color: var(--Color-80, rgba(0, 0, 0, 0.8));

  /* H4 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 166.667% */
}

.EReceipt-software-bottom-right {
  width: 40%;
}
.EReceipt-software-img {
  width: 100%;
}

@media (max-width: 768px) {
  .EReceipt-software-bottom {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: var(--xl, 24px);
    align-self: stretch;
  }
  .EReceipt-software-bottom-right {
    width: 100%;
  }

  .EReceipt-software-bottom-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--lg, 16px);
    align-self: stretch;
  }

  .EReceipt-software-bottom-left-text1 {
    color: var(--Foundation-Blue-Normal, #2b5996);
    /* text-18semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .EReceipt-software-bottom-left-text2 {
    color: var(--Color-80, rgba(0, 0, 0, 0.8));
    /* text-18regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
