.why-choose-cards {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
  padding-left: 5%;
  padding-right: 5%;
  flex-wrap: wrap;
}

.why-choose-card {
  display: flex;
  padding: var(--sm-secion, 40px) var(--xl, 24px);
  align-items: center;
  gap: var(--lg, 16px);
  flex: 1 0 0;

  border-radius: var(--lg, 16px);
  border: 1px solid var(--foundation-blue-light-active, #bdccde);
  background: var(--white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.2s;
}

.why-choose-card-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  flex: 1 0 0;
}

.why-choose-card-head {
  color: var(--Foundation-Blue-Darker, #0f1f35);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.why-choose-card-text {
  color: var(--333333, rgba(0, 0, 0, 0.72));

  /* text-18meduim */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.why-choose-card:hover {
  border-radius: var(--lg, 16px);
  border-right: 16px solid var(--Foundation-Blue-Normal, #1ec2f3);
  border-left: 16px solid var(--Foundation-Blue-Normal, #1ec2f3);
  background: var(--Foundation-Blue-Light, #e9f9fe);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}
.why-choose-card-svg-mobileView {
  display: none;
}
@media (max-width: 768px) {
  .why-choose-card {
    width: 100%;
    display: flex;
    height: 143px;
    padding: var(--xl, 24px) var(--lg, 16px);
    align-items: center;
    gap: var(--md, 8px);
    align-self: stretch;
    border-radius: var(--lg, 16px);
    border: 1px solid var(--foundation-blue-light-active, #bdccde);
    background: var(--white, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  }

  .why-choose-card:hover {
    border-radius: var(--lg, 16px);
    border-right: 8px solid var(--Foundation-Blue-Normal, #1ec2f3);
    border-left: 8px solid var(--Foundation-Blue-Normal, #1ec2f3);
    background: var(--Foundation-Blue-Light, #e9f9fe);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  }

  .why-choose-card-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--lg, 16px);
    flex: 1 0 0;
  }

  .why-choose-card-head {
    color: var(--Foundation-Blue-Darker, #0f1f35);

    /* text-16semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .why-choose-card-text {
    color: var(--333333, rgba(0, 0, 0, 0.72));

    /* text-12regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .why-choose-card-svg-mobileView {
    display: block;
  }

  .why-choose-card-svg-regularView {
    display: none;
  }
}
