.card {
  display: flex;
  height: 320px;
  padding: var(--xl, 24px) var(--lg, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--xl, 24px);
  border-radius: var(--md, 8px);
  background: var(--light-32, rgba(234, 238, 245, 0.32));
  width: 30.333%;
}

.card-head {
  display: flex;
  padding: var(--xl, 0.7rem) var(--xxl, 2rem);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--md, 8px);
  background: var(--Foundation-Blue-Normal, #1ec2f3);
}

.card-text {
  color: var(--white, #fff);

  /* text-32semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.points-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xl, 10px);
  align-self: stretch;
}

.point {
  display: flex;
  gap: var(--md, 5px);
  align-self: stretch;
}

.point-text {
  color: var(--Foundation-Blue-Darker, #0f1f35);

  /* text-16regular */
  font-family: "IBM Plex Sans Arabic";
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.point-checkMark {
  width: 30px;
  height: 30px;
}

.card:hover {
  background: #2b5996;
  .point-text {
    color: white;
  }
}

@media (max-width: 1024px) {
  .card {
    width: 50%; /* Adjust to two cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .card {
    width: 100%; /* Full width for each card on smaller screens */
  }

  

  .card-text {
    font-size: 1rem; /* Slightly smaller font size */
  }

  .point-checkMark {
    width: 25px;
    height: 25px;
  }

  .point-text {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .card {
    padding: var(--md, 12px); /* Adjust padding for very small screens */
  }

  .card-text {
    font-size: 0.9rem; /* Smaller font size */
  }

  .point-text {
    font-size: 0.8rem; /* Further adjust font size */
  }

  .point-checkMark {
    width: 20px;
    height: 20px; /* Smaller icons */
  }
}