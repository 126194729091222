.EInvoice-solution-bottom{
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 64px;
}

.EInvoice-solution-bottom img {
    width: 100%;
}

