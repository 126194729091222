.EInvoice-banner {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align the content higher */
  width: 100%;
  height: 100vh; /* Full-screen height */
  background: url("../../assets/EInvoiceBanner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 7rem 2rem; /* Increase padding at the top */
  position: relative;
}

.EInvoice-banner-content {
  z-index: 2; /* Make sure the content is above the overlay */
  color: #fff;
  max-width: 1200px;
  padding: 2rem;
  text-align: center;
  margin-top: 4rem; /* Move content further from the top */
}

.EInvoice-text {
  color: var(--white, #fff);
  text-align: center;

  /* H1 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 128.571% */
}

.EInvoice-text2 {
  color: var(--Foundation-Blue-Normal, #1ec2f3);
  text-align: center;

  /* text-48semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1200px) {
  .EInvoice-text {
    font-size: 48px;
    line-height: 64px;
  }

  .EInvoice-text2 {
    font-size: 40px;
  }
}

@media (max-width: 992px) {
  .EInvoice-banner {
    padding: 5rem 1.5rem;
  }

  .EInvoice-text {
    font-size: 40px;
    line-height: 56px;
  }

  .EInvoice-text2 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .EInvoice-banner {
    padding: 4rem 1rem;
    height: auto; /* Allow the height to adjust with content */
  }

  .EInvoice-text {
    font-size: 32px;
    line-height: 48px;
  }

  .EInvoice-text2 {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .EInvoice-banner-content {
    margin-top: 0px;
  }

  .EInvoice-banner {
    padding: 3rem 1rem;
  }

  .EInvoice-text {
    font-size: 28px;
    line-height: 40px;
  }

  .EInvoice-text2 {
    font-size: 24px;
  }
}
