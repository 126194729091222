/* Banner Button Styling */
.banner-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .banner-button {
    display: flex;
    width: 170px;
    height: 55px;
    padding: 10px var(--lg, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--sm, 4px);
    border-radius: 16px;
    background: linear-gradient(0deg, #fff 0%, #fff 100%);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .banner-button-text {
    color: var(--Foundation-Blue-Normal, #2b5996);
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .banner-button:hover {
    background-color: #2b5996;
  }
  
  /* .banner-button:hover .banner-button-text {
    color: #fff;
  }
   */
  /* Responsive Design */
  @media (max-width: 768px) {
    .banner-button {
      width: 150px;
      height: 50px;
    }
  
    .banner-button-text {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .banner-button {
      width: 120px;
      height: 45px;
    }
  
    .banner-button-text {
      font-size: 0.7rem;
    }
  }
  