.oddoo-operations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap; /* Allows wrapping to adjust on smaller screens */
}

.oddoo-operation-img1 {
  width: 50%;
}

@media (max-width: 480px) {

  .oddoo-operations{
    flex-direction: column-reverse;
  }
  .oddo-operation-img2{
    width: 80%;
  }
  .oddoo-operation-img1{
    width: 100%;
    margin-top: 2rem;
  }
}