.button {
  display: flex;
  width: 200px;
  height: 60px;
  padding: 10px var(--lg, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--sm, 4px);
  border-radius: 16px;
  background: linear-gradient(
      rgba(43, 89, 150, 0.9),
      rgba(47, 184, 234, 0.9)
       
    ),
    url(../../assets/backgroundVectors.png); 

  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.button-text {
  color: var(--white, #fff);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
