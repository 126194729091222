.odoo-solutions-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
  flex-wrap: wrap;
}

.odoo-solutions-bottomLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xxl, 32px);
  flex: 1 0 0;
}

.odoo-solution {
  display: flex;
  align-items: center;
  gap: var(--md, 8px);
  align-self: stretch;
}

.odoo-solution-text {
  color: var(--Foundation-Blue-Darker, #0f1f35);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.odoo-solution-bottomRight {
  /* background: url(../../assets/odooSolutionBackGround1.png); */
  /* background-size: cover; */
  width: 50%;
  /* height: 400px; */
  /* position: relative; */
}

.oddo-solution-svg-conatiner {
  display: flex;
  width: 100%;
  height: 397.616px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.odooSolution-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
}

.oddo-solution-svg-mobile-view {
  display: none;
}

@media (max-width: 480px) {
  .odoo-solution-text{
    font-size: 16px;
  }
  .oddo-solution-svg-conatiner {
    display: flex;
    width: 340px;
    height: 218.048px;
    padding: 19.42px 30px 19.059px 30px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .odooSolution-img{
    position: absolute;
    top: 54%;
    left: 45%;
    transform: translate(-45%, -54%);
    width: 200px;
    height: 200px;
  }
  .oddo-solution-svg-regular-view {
    display: none;
  }

  .oddo-solution-svg-mobile-view {
    display: block;
  }
  .odoo-solutions-bottom {
    flex-direction: column-reverse;
  }
  .odoo-solution-bottomRight {
    width: 100%;
  }
  .odoo-solutions-bottomLeft {
    width: 100%;
  }
}

/* .odoo-solution-bottomRight:hover{
    background: url(../../assets/odooSolutionBackGround2.png);

} */
