.EInvoicing-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.EInvoicing-bottom-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lg-section, 56px);
  flex: 1 0 0;
  width: 50%;
}

.EInvoicing-bottom-text1 {
  color: var(--Foundation-Blue-Normal, #1ec2f3);

  /* text-48semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.EInvoicing-bottom-text2 {
  color: var(--Color-80, rgba(0, 0, 0, 0.8));

  /* H4 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.EInvoicing-bottom-right {
  width: 50%;
}

@media (max-width: 1200px) {
  .EInvoicing-bottom {
    flex-direction: column;
  }

  .EInvoicing-bottom-left {
    width: 100%;
    align-items: center; /* Center content for smaller screens */
  }

  .EInvoicing-bottom-right {
    width: 80%; /* Adjust image width */
    margin-top: 2rem;
  }
}

@media (max-width: 992px) {
  .EInvoicing-bottom-text1 {
    font-size: 2rem;
  }

  .EInvoicing-bottom-text2 {
    font-size: 1.4rem;
    line-height: 36px;
  }

  .EInvoicing-bottom-right {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .EInvoicing-bottom {
    padding: 1.5rem;
  }

  .EInvoicing-bottom-text1 {
    font-size: 1.8rem;
  }

  .EInvoicing-bottom-text2 {
    font-size: 1.3rem;
    line-height: 32px;
  }
}

@media (max-width: 480px) {
  .EInvoicing-bottom-left {
    gap: 16px;
  }
  .EInvoicing-bottom {
    flex-direction: column-reverse;
  }

  .EInvoicing-bottom-right-img {
    width: 100%;
  }
  .EInvoicing-bottom-text1 {
    color: var(--Foundation-Blue-Normal, #1ec2f3);

    /* text-18semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .EInvoicing-bottom-text2 {
    color: var(--Color-80, rgba(0, 0, 0, 0.8));

    /* text-18regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .EInvoicing-bottom-right {
    width: 100%;
    margin-top: 1.5rem;
  }
}
