.contactUs-bottomContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 20px; /* Add some spacing between left and right content */
  flex-wrap: wrap;
}

.contactUs-bottomLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xxl, 15px);
  flex: 1 0 0;
}

.requestDemo-text {
  color: var(--Dark, #333);
  text-align: center;

  /* text-32semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xl, 24px);
  align-self: stretch;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--lg, 10px);
  align-self: stretch;
  margin-top: 10px;
}

.label {
  color: var(--Dark, #333);

  /* text-18meduim */
  font-family: "IBM Plex Sans Arabic";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input {
  display: flex;
  height: 50px;
  padding: 10px var(--Space-lg, 16px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--Space-lg, 16px);
  border: 1px solid var(--foundation-blue-light-active, #bdccde);
  background: rgba(234, 238, 245, 0.24);
}

.input:focus {
  border-radius: var(--md, 8px);
  border: 2px solid var(--Foundation-Blue-Normal, #2b5996);
  background: rgba(234, 238, 245, 0.24);
  outline: none;
}

.contactUs-img {
  width: 500px;
  height: 500px;
}

.contactUs-form {
  width: 100%;
}

@media (max-width: 1024px) {
  .contactUs-bottomContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .contactUs-img {
    width: 400px;
    height: 400px;
    align-self: center; /* Center the image on smaller screens */
  }

  .requestDemo-text {
    font-size: 1.4rem; /* Adjust font size */
  }
}

@media (max-width: 768px) {
  .contactUs-img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .contactUs-bottomContent {
    flex-direction: column-reverse;
  }
  .contactUs-bottomRight {
    width: 100%;
  }
  .contactUs-bottomLeft {
    width: 100%;
  }

  .contactUs-img {
    width: 100%;
    height: 100%;
    margin-bottom: 5px; /* Add spacing below image */
  }
}
