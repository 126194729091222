.odoo-business1-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

.odoo-business1 {
  display: flex;
  padding: var(--xl, 24px) var(--lg, 16px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--lg, 16px);
  background: var(--Foundation-Blue-Light, #eaeef5);
  color: var(--Foundation-Blue-Normal, #2b5996);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s;
  width: 30.333%;
}

.odoo-business1:hover {
  background: var(--foundation-blue-dark-active, #132844);
  color: var(--white, #fff);
}

@media (max-width: 480px) {
  .odoo-business1-bottom {
    gap: 8px;
  }
  .odoo-business1 {
   width: 50%;
    display: flex;
    padding: var(--lg, 16px) var(--md, 8px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;

  }
}
