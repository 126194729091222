.why-choose-solution-bottom-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;
}

.why-choose-soltion-container {
  position: relative;
  width: 29%;
}

.why-choose-solution-span {
  position: absolute;
  top: 17px;
  left: -50px;
}

.why-choose-solution {
  display: flex;

  padding: var(--xxl, 15px) 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white, #fff);
  text-align: center;
  /* text-32semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  background: var(--foundation-blue-normal-hover, #1bafdb);
}

.why-choose-solution-bottom-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--xl, 60px);
  margin-top: 64px;
}

.why-choose-solution-mobileView {
  display: none;
}

@media (max-width: 768px) {
  .why-choose-solution-span {
    top: 13px;
    left: -19px;
  }

  .why-choose-solution-bottom-bottom{
    gap: 28px;
  }

  .why-choose-solution {
    color: var(--white, #fff);
    text-align: center;
    /* text-16semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .why-choose-solution-mobileView {
    display: block;
  }
  .why-choose-solution-regularView {
    display: none;
  }
}
