.stage-of-work-imgContainer{
    text-align: center;

}

.stage-of-work-img{
    width: 750px;
    height: 750px;
}

@media only screen and (max-width: 768px) {
    .stage-of-work-img{
        width: 100%;
        height: 500px
    }
}