.EInvoice-key-features-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.key-feature-container {
  flex: 0 1 calc(33.333% - 20px); /* Set the width to 33.333% with space for gaps */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px; /* Add some margin to separate rows */
}

.key-feature-top {
  color: var(--foundation-blue-light-hover, #ddf6fd);

  font-family: "IBM Plex Sans Arabic";
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 90% */
}

.key-feature-bottom {
  display: flex;
  padding-left: var(--sm-secion, 40px);
  flex-direction: column;
  align-items: center;
  gap: var(--lg, 16px);
  align-self: stretch;
}

.key-feature-bottom-top {
  display: flex;
  align-items: center;
  gap: var(--md, 8px);
  align-self: stretch;
}

.key-feature-bottom-top-text {
  color: var(--Foundation-Blue-Normal, #2b5996);

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.key-feature-bottom-bottom {
  color: var(--Dark, #333);

  /* text-18meduim */
  font-family: "IBM Plex Sans Arabic";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 1024px) {
  .key-feature-container {
    flex: 0 1 calc(50% - 20px); /* Adjust for 2 items per row on smaller screens */
  }
}

@media (max-width: 768px) {
  .key-feature-top {
    color: var(--foundation-blue-light-hover, #ddf6fd);

    /* text-32semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .key-feature-bottom {
    display: flex;
    padding-left: var(--sm, 4px);
    flex-direction: column;
    align-items: center;
    gap: var(--lg, 16px);
    align-self: stretch;
  }

  .key-feature-bottom-top {
    display: flex;
    align-items: center;
    gap: var(--md, 8px);
    align-self: stretch;
  }

  .key-feature-bottom-top img {
    width: var(--xl, 24px);
    height: var(--xl, 24px);
  }

  .key-feature-bottom-top-text {
    color: var(--Foundation-Blue-Normal, #2b5996);

    /* text-16semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .key-feature-bottom-bottom {
    color: var(--Dark, #333);

    /* text-12regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .key-feature-container {
    flex: 0 1 50%; /* Single column on very small screens */
  }
}
