/* Banner Styling */
.banner {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align the content higher */
    width: 100%;
    height: 100vh; /* Full-screen height */
    background-image: url("../../assets/carousel 1.png");
    
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 7rem 2rem; /* Increase padding at the top */
    position: relative;
  }
  
  
  
  .banner-content {
    z-index: 2; /* Make sure the content is above the overlay */
    color: #fff;
    max-width: 1200px;
    padding: 2rem;
    text-align: center;
    margin-top: 5rem; /* Move content further from the top */
  }
  
  .banner-text {
    font-size: 3rem;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    color: #fff;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 2rem;
  }
  
  /* Highlight for specific words */
  .highlight {
    color: var(--Foundation-Blue-Normal, #1EC2F3); /* Custom color for 'software' */
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .banner-text {
      font-size: 1.8rem;
    }
  
    .banner-content {
      margin-top: 3rem; /* Adjust margin for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .banner-text {
      font-size: 1.2rem;
    }
  
    .banner-content {
      margin-top: 2rem; /* Adjust margin for smaller screens */
    }
  }
  