.EReceipt-application-bottom {
  display: flex;
  align-items: center;
  gap: var(--xl, 24px);
  align-self: stretch;
  flex-wrap: wrap;
}

.EReceipt-application-container {
  width: 30.333%;
  display: flex;
  padding: var(--xl, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--xl, 24px);
  /* flex: 1 0 0; */
  align-self: stretch;
  border-radius: var(--lg, 16px);
  border: 1px solid var(--foundation-blue-light-active, #bdccde);
  background: rgba(234, 238, 245, 0.16);
  transition: all 0.2s;
}

.EReceipt-application-img-container {
  display: flex;
  width: 108px;
  height: 108px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 88px;
  background: var(--foundation-blue-light-hover, #dfe6ef);
}

.EReceipt-application-text1 {
  color: var(--Foundation-Blue-Normal, #2b5996);
  text-align: center;

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.EReceipt-application-text2 {
  color: var(--333333, rgba(0, 0, 0, 0.72));
  text-align: center;

  /* text-18meduim */
  font-family: "IBM Plex Sans Arabic";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.EReceipt-application-container:hover {
  border-radius: var(--lg, 16px);
  border: 2px solid var(--foundation-blue-light-active, #bdccde);
  background: var(--Foundation-Blue-Normal, #2b5996);
  box-shadow: 0px 12px 0px 0px #1ec2f3;

  .EReceipt-application-text1 {
    color: var(--white, #fff);
  }
  .EReceipt-application-text2 {
    color: var(--white, #fff);
  }
  .EReceipt-application-img-container {
    border-radius: 88px;
    background: var(--white, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 768px) {
  .EReceipt-application-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--lg, 16px);
    align-self: stretch;
  }

  .EReceipt-application-container{
    width: 100%;
  }
}
