.partners-container {
  display: flex;
  align-items: flex-start;
  gap: var(--xl, 24px);
  align-self: stretch;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .partners-container{
    gap: 5px;
  }
}