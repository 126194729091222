.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  margin-top: 2rem;
}

.footer-topContent {
  display: flex;
  width: 100%;
  padding: 10px 64px;
  align-items: center;
  justify-content: center;
  gap: var(--xl, 24px);
  background: linear-gradient(rgba(43, 89, 150, 0.9), rgba(43, 89, 150, 0.9)),
    url(../../assets/backgroundVectors.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--xl, 20px);
  width: 33.333%;
  height: 100%;
}

.footer-contactUs,
.footer-address {
  display: flex;
  align-items: flex-start;
  gap: var(--md, 8px);
  align-self: stretch;
}

.footer-text,
.phoneNumber,
.location,
.footer-link,
.links-header {
  color: var(--Color-white, #fff);
  font-family: "IBM Plex Sans Arabic";
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s;
}

.footer-link:hover{
  text-decoration: underline;
}

.footer-text,
.phoneNumber,
.location,
.footer-link {
  font-size: 1rem;
  font-weight: 500;
}

.links-header {
  font-size: 24px;
  font-weight: 600;
}

.footer-bottomContent {
  display: flex;
  padding: var(--xl, 10px) 7px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--sm, 4px);
}

.copyRight-text {
  color: var(--Color-80, rgba(0, 0, 0, 0.8));
  font-size: 1rem;
  font-weight: 400;
}

.phoneNumbers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--lg, 2px);
}

.phoneNumber-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

/* ============================================================================ */
@media (max-width: 768px) {
  .footer-topContent {
    flex-direction: column;
    padding: 20px;
    gap: var(--md, 16px);
  }

  .footer-section {
    width: 100%;
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Ensure text is aligned left */
  }

  .footer-contactUs,
  .footer-address {
    flex-direction: row;
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Ensure text is aligned left */
  }

  .links-container {
    justify-content: flex-start; /* Align icons to the left */
  }
}

@media (max-width: 480px) {
  .footer-text,
  .phoneNumber,
  .location,
  .footer-link {
    font-size: 0.875rem; /* 14px */
  }

  .links-container {
    display: flex;
    align-items: flex-start;
    gap: var(--md, 8px);
  }

  .links-header {
    font-size: 20px;
  }

  .footer-bottomContent {
    display: flex;
    padding: var(--lg, 16px) 10px;
    justify-content: center;
    align-items: center;
    gap: var(--sm, 4px);
    align-self: stretch;
  }

  .footer-topContent {
    padding: 15px;
  }

  .footer-section {
    gap: var(--lg, 12px);
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Ensure text is aligned left */
  }

  .footer-contactUs,
  .footer-address {
    gap: var(--sm, 4px);
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Ensure text is aligned left */
  }

  .phoneNumbers-container,
  .locations-container {
    gap: var(--sm, 4px);
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Ensure text is aligned left */
  }
}
