.oddoo-sales {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;
}

.oddoo-sales-leftSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xl, 24px);
}

.oddoo-sales-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: var(--xxl, 32px); */
}

.oddoo-sales-img {
  width: 40%;
}

@media (max-width: 480px) {
  .oddoo-sales{
    flex-direction: column-reverse;
  }
  .oddoo-sales-leftSide{
    width: 100%;
    margin-top: 2rem;
    justify-content: space-between;
  }

  .oddoo-sales-img {
    width: 90%;
    
  }
}
