.partner-banner {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align the content higher */
  width: 100%;
  height: 100vh; /* Full-screen height */
  background: url("../../assets/partnerBanner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 7rem 2rem; /* Increase padding at the top */
  position: relative;
}

.partner-banner-content {
  z-index: 2; /* Make sure the content is above the overlay */
  color: #fff;
  max-width: 1200px;
  padding: 2rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--xxl, 32px);
}

.partner-banner-header {
  color: var(--white, #fff);
  text-align: center;

  /* H1 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 128.571% */
}

.partner-banner-text {
  color: var(--white, #fff);
  text-align: center;

  /* text-24semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.partner-banner-container {
  display: flex;

  padding: 8px;

  align-items: center;
  gap: 10px;
  border-radius: 224px;
  border: 3px dashed#1EC2F3;
}

.partner-banner-img-container {
  display: flex;
  width: 208px;
  height: 208px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--xl, 5px);
  flex-shrink: 0;
  border-radius: 180px;
  background: var(--Foundation-Blue-Normal, #1ec2f3);
}

.partner-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--lg, 0px);
  align-self: stretch;
}

.partner-container-span {
  color: #fff;
  text-align: center;
  /* text-40semi */
  font-family: "IBM Plex Sans Arabic";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

.partner-container-text {
  color: #fff;
  text-align: center;
  /* H4 */
  font-family: "IBM Plex Sans Arabic";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 166.667% */
}

.partner-bottom-container {
  display: flex;
  align-items: flex-start;
  gap: var(--xl, 24px);
  align-self: stretch;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .partner-banner-content {
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: center;
    gap: var(--sm-secion, 40px);
  }

  .partner-banner-header {
    color: var(--white, #fff);
    text-align: center;
    /* text-24semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .partner-banner-text {
    color: var(--white, #fff);
    text-align: center;
    /* text-16regular */
    font-family: "IBM Plex Sans Arabic";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .partner-banner-container {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .partner-banner-img-container {
    display: flex;
    width: 156px;
    height: 156px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--lg, 0px);
    flex-shrink: 0;
  }

  .partner-banner-img-container img {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
  }

  .partner-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--lg, 0px);
    align-self: stretch;
  }

  .partner-container-span {
    color: #fff;
    text-align: center;

    /* text-32semi */
    font-family: "IBM Plex Sans Arabic";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .partner-container-text {
    color: #fff;
    text-align: center;
    /* text-18meduim */
    font-family: "IBM Plex Sans Arabic";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
